/**
 * 优惠券最优解相关 实现
 */
import { fetch, post } from 'api/base/axios'
import {fmtOrderGoodsInfo, fmtOrderInfo} from 'api/settlement/order/admin'
import { db_time_to_local_fmt, delta_time_secdons } from 'utils/timeutils'

function get_good_price(goods_item){

  let goods_price = 0;

  switch (goods_item.type) {
    case 'curriculum': {
      goods_price = goods_item.goods_info.promote_price;
    } break;
    case 'vip': {
      goods_price = goods_item.goods_info.promote_price;
    } break;
  }

  return goods_price;

}

function get_goods_best_discount(goods_item, used_coupons_uuid_list){

  let max_discount_amount = 0;
  let max_discount_type = null;
  let max_discount_uuid = null;
  let max_discount_desc = '';
  let max_discount_promotes_item = null;

  let goods_price = get_good_price(goods_item);

  // vip check
  goods_item.promotes_dict.vips.forEach(item => {

    let vip_discount = get_goods_discount_amount(goods_price, 'vip', item);

    if (vip_discount.discount_amount > max_discount_amount) {
      max_discount_amount = vip_discount.discount_amount;
      max_discount_desc = vip_discount.discount_desc;
      max_discount_type = 'vip';
      max_discount_uuid = item.uuid;
      max_discount_promotes_item = item;
    }

  });

  // coupons check
  goods_item.promotes_dict.coupons.forEach(item => {

    if (!used_coupons_uuid_list.includes(item.uuid)) {
      let coupons_discount = get_goods_discount_amount(goods_price, 'coupons', item);
      if (coupons_discount.discount_amount > max_discount_amount) {
        max_discount_amount = coupons_discount.discount_amount;
        max_discount_desc = coupons_discount.discount_desc;
        max_discount_type = 'coupons';
        max_discount_uuid = item.uuid;
        max_discount_promotes_item = item;
      }
    } else {
      console.debug(`Coupons user ${item.uuid} is used , skipped`)
    }

  });

  return {
    max_discount_amount: max_discount_amount,
    discount_type: max_discount_type,
    discount_uuid: max_discount_uuid,
    max_discount_desc: max_discount_desc,
    promotes_item: max_discount_promotes_item,
  }

}

export function get_goods_discount_amount(goods_total, promotes_type, promotes_item){

  let discount_amount = 0;
  let goods_amount = goods_total;
  let discount_desc = '';

  switch(promotes_type){
    case 'vip' : {

      let vip_card = promotes_item.vip_card ? promotes_item.vip_card : promotes_item;

      switch(vip_card.type){
        case 'discount': {
          discount_amount = goods_total * (10 - vip_card.discount_val) / 10.0;
          discount_desc = `${vip_card.discount_val}折会员卡`;
        } break;
        case 'free': {
          discount_amount = goods_total;
          discount_desc = `免费会员卡`;
        } break;
      }

    } break;
    case 'coupons' : {

      let coupons_instance = promotes_item.coupons ? promotes_item.coupons : promotes_item;
      let discount_strength = coupons_instance.discount_strength;
      let limit_price = coupons_instance.limit_price;
      let coupons_discount = 0;

      switch(coupons_instance.coupons_type){
        case 'discount': {

            coupons_discount = goods_total * (10 - discount_strength) / 10.0;
            if (limit_price && coupons_discount >= limit_price){
              coupons_discount = limit_price;
            }

            let limit_text = limit_price !== 0 ? `${limit_price}元封顶` : '不封顶';

            discount_desc = `${discount_strength}折优惠券（${limit_text}）`;

        } break;
        case 'deducted': {

          if (discount_strength >= goods_total){
            coupons_discount = goods_total
          } else {
            coupons_discount = discount_strength;
          }

          let limit_text = limit_price !== 0 ? `满${limit_price}元可用` : '无门槛';

          discount_desc = `${discount_strength}元优惠券（${limit_text}）`;

        } break;
      }

      discount_amount = coupons_discount;

    } break;
  }

  if (goods_total >= discount_amount) {
    goods_amount = goods_total - discount_amount;
  } else {
    goods_amount = 0;
    discount_amount = goods_total;
  }

  return {
    discount_amount: parseFloat(discount_amount.toFixed(2)),
    goods_amount: parseFloat(goods_amount.toFixed(2)),
    discount_desc: discount_desc
  };

}

export function selectBestDiscount(order_goods){

  let promotes_dict = {'cur': {}, 'vip': {}};
  let _order_goods_copy = JSON.parse(JSON.stringify(order_goods));

  // 商品价格,从大到小排序
  let goods_length = _order_goods_copy.length;
  for (let i = 0; i < goods_length - 1; i++) {
    for (let j = i + 1; j < goods_length; j++) {

      if (get_good_price(_order_goods_copy[j]) > get_good_price(_order_goods_copy[i])) {

        let swap = _order_goods_copy[i];
        _order_goods_copy[i] = _order_goods_copy[j];
        _order_goods_copy[j] = swap;

      }

    }

  }

  let temp_arr = [];
  for (let i = 0; i < goods_length; i++) {
    temp_arr.push(get_good_price(_order_goods_copy[i]));
  }
  console.log("price_order_list", temp_arr);

  var begin = new Date().getTime();
  console.debug('[LOCAL]Matching discount optimal solution start ...');

  let used_coupons_uuid_list = [];
  _order_goods_copy.forEach((item, index) => {

    switch (item.type) {
      case 'curriculum': {

        let best_discount = get_goods_best_discount(item, used_coupons_uuid_list);
        item.best_discount = best_discount;

        if (best_discount.discount_type === 'coupons'){
          used_coupons_uuid_list.push(best_discount.promotes_item.uuid);
        }

        console.log(item);

        promotes_dict['cur'][item.res_uuid] = best_discount;

      } break;
      case 'vip': {
        console.debug(`goods vip ${item.res_uuid} no supported, skipped`)
      } break;
    }

  });

  var end = new Date().getTime();
  console.debug(`[LOCAL]Matching discount optimal solution ended, script excute duration in ${end - begin} ms`);

  return promotes_dict;

}

